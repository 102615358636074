import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

import Select from "../../components/ui/Select";
import DateInput from "../../components/ui/DateInput";
import { selectActiveDoctors } from "../../redux/doctorsSlice";
import { selectActiveProcedures } from "../../redux/proceduresSlice";
import { selectActiveHealthInsurances } from "../../redux/healthInsurancesSlice";
import { selectUserDoctorId } from "../../redux/authSlice";

export default function Filter({ onFilter }) {
  const all = "0";
  const doctors = useSelector(selectActiveDoctors);
  const procedures = useSelector(selectActiveProcedures);
  const healthInsurances = useSelector(selectActiveHealthInsurances);

  const doctorId = useSelector(selectUserDoctorId);

  const [date, setDate] = useState(moment().startOf("day"));
  const [doctor, setDoctor] = useState(all);
  const [procedure, setProcedure] = useState(all);
  const [healthInsurance, setHealthInsurance] = useState(all);

  useEffect(() => {
    if (!date.isValid()) return;

    const filters = {
      date: date,
    };

    if (doctorId) {
      filters.doctor = doctorId;
    } else if (doctor !== all) {
      filters.doctor = doctor;
    }

    if (procedure !== all) {
      filters.procedure = procedure;
    }

    if (healthInsurance !== all) {
      filters.healthInsurance = healthInsurance;
    }

    onFilter(filters);
  }, [date, doctor, healthInsurance, procedure, doctorId, doctors, onFilter]);

  return (
    <Card>
      <CardContent>
        <h3>Filtros</h3>

        <DateInput label="Data" onChange={setDate} value={date} />

        <Select
          label="Procedimento"
          value={procedure}
          onChange={setProcedure}
          items={procedures}
          idKey="id"
          valueKey="description"
          all={true}
        />
        
        {!doctorId && (
          <Select
            label="Médico"
            value={doctor}
            onChange={setDoctor}
            items={doctors}
            idKey="id"
            valueKey="name"
            all={true}
          />
        )}

        <Select
          label="Convênio"
          value={healthInsurance}
          onChange={setHealthInsurance}
          items={healthInsurances}
          idKey="id"
          valueKey="description"
          all={true}
        />
      </CardContent>
    </Card>
  );
}
