import AppointmentsPage from "../pages/appointments/AppointmentsPage";
import AppointmentFormPage from "../pages/appointmentForm/AppointmentFormPage";
import LoginPage from "../pages/login/LoginPage";
import { authRoles } from "./helpers";
import RecoverPasswordPage from "../pages/recoverPassword/RecoverPasswordPage";
import DoctorsPage from "../pages/doctors/DoctorsPage";
import ProceduresPage from "../pages/procedures/ProceduresPage";
import HealthInsurancesPage from "../pages/healthInsurances/HealthInsurancesPage";
import UsersPage from "../pages/users/UsersPage";
import PaymentReportPage from "../pages/paymentReport/PaymentReportPage";
import ReceivablesReportPage from "../pages/receivablesReport/ReceivablesReportPage";
import GenericReportPage from "../pages/genericReport/GenericReportPage";

export const routes = {
  createAppointment: {
    title: "Agendamentos",
    path: "/agendamentos/:selectedDate?",
    createLink: (date) => `/agendamentos/${date}`,
    component: AppointmentFormPage,
    roles: [authRoles.secretary, authRoles.admin],
  },
  editAppointment: {
    title: "Agendamentos",
    path: "/agendamentos/editar/:id",
    createLink: (id) => `/agendamentos/editar/${id}`,
    component: AppointmentFormPage,
    roles: [authRoles.secretary, authRoles.admin],
  },
  appointments: {
    title: "Agendamentos",
    path: "/",
    createLink: () => "/",
    exact: true,
    component: AppointmentsPage,
  },
  doctors: {
    title: "Médicos",
    path: "/medicos",
    createLink: () => "/medicos",
    component: DoctorsPage,
    roles: [authRoles.admin],
  },
  healthInsurances: {
    title: "Convênios",
    path: "/convenios",
    createLink: () => "/convenios",
    component: HealthInsurancesPage,
    roles: [authRoles.admin],
  },
  procedures: {
    title: "Procedimentos",
    path: "/procedimentos",
    createLink: () => "/procedimentos",
    component: ProceduresPage,
    roles: [authRoles.admin],
  },
  paymentReport: {
    title: "Relatório de Repasse Mensal",
    path: "/relatorios/pagamento",
    createLink: () => "/relatorios/pagamento",
    component: PaymentReportPage,
    roles: [authRoles.secretary, authRoles.admin],
  },
  receivablesReport: {
    title: "Relatório de Atendimento Mensal",
    path: "/relatorios/recebimento",
    createLink: () => "/relatorios/recebimento",
    component: ReceivablesReportPage,
    roles: [authRoles.secretary, authRoles.admin],
  },
  genericReport: {
    title: "Relatório Genérico",
    path: "/relatorios/generico",
    createLink: () => "/relatorios/generico",
    component: GenericReportPage,
    roles: [authRoles.secretary, authRoles.admin],
  },
  users: {
    title: "Usuários",
    path: "/usuarios",
    createLink: () => "/usuarios",
    component: UsersPage,
    roles: [authRoles.admin],
  },
  login: {
    path: "/",
    createLink: () => "/",
    exact: true,
    component: LoginPage,
  },
  recoverPassword: {
    path: "/recuperar-senha",
    createLink: () => "/recuperar-senha",
    component: RecoverPasswordPage,
  },
};

export const authenticatedRoutes = [
  routes.doctors,
  routes.healthInsurances,
  routes.procedures,
  routes.users,
  routes.paymentReport,
  routes.receivablesReport,
  routes.genericReport,
  routes.editAppointment,
  routes.createAppointment,
  routes.appointments,
];

export const unauthenticatedRoutes = [routes.recoverPassword, routes.login];

export const isRouteAllowed = (role, route) =>
  role === authRoles.admin || !route.roles || route.roles?.indexOf(role) >= 0;
