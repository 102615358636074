import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../util/helpers";
import { db } from "../services/firebase";

const proceduresAPI = () => {
  let subscription;

  const subscribe = (updateCallback) => {
    if (!subscription) {
      subscription = db
        .collection("procedures")
        .orderBy("description")
        .onSnapshot((snapshot) => {
          const items = snapshot.docs?.map((x) => x.data());
          updateCallback(items);
        });
    }
  };

  const unsubscribe = () => {
    if (subscription) {
      subscription();
    }
  };

  const archive = (id) => db.doc(`procedures/${id}`).update({ archived: true });

  const create = (model) => {
    const doc = db.collection(`procedures`).doc();

    return doc.set({
      ...model,
      id: doc.id,
      archived: false,
    });
  };

  const update = (id, model) => db.doc(`procedures/${id}`).update(model);

  return { subscribe, unsubscribe, archive, create, update };
};

const api = proceduresAPI();

const initialState = {
  items: [],
  status: asyncStatus.loading,
};

export const proceduresSlice = createSlice({
  name: "procedures",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload;
      state.status = asyncStatus.completed;
    },
  },
});

const { updateItems } = proceduresSlice.actions;

export const selectProcedures = (state) => state.procedures.items;

export const selectActiveProcedures = (state) =>
  state.procedures.items?.filter((x) => !x.archived);

export const observeProcedures = () => (dispatch) =>
  api.subscribe((x) => dispatch(updateItems(x)));

export const findProcedureById = (id) => (dispatch, getState) =>
  getState().procedures.items?.find((x) => x.id === id);

export const archiveProcedure = (id) => () => api.archive(id);

export const createProcedure = (model) => () => api.create(model);

export const updateProcedure = (id, model) => () => api.update(id, model);

export default proceduresSlice.reducer;
