import { createSlice } from "@reduxjs/toolkit";

import { authStates } from "../util/helpers";
import { auth } from "../services/firebase";

const authAPI = () => {
  let subscription;

  const subscribe = (updateCallback) => {
    if (!subscription) {
      subscription = auth.onAuthStateChanged((user) =>
        !user
          ? updateCallback()
          : user.getIdTokenResult().then((token) =>
              updateCallback({
                user: { displayName: user.displayName, email: user.email },
                claims: {
                  role: token.claims?.role,
                  doctorId: token.claims?.doctorId,
                },
              })
            )
      );
    }
  };

  const unsubscribe = () => {
    if (subscription) {
      subscription();
    }
  };

  const login = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);

  const logout = () => auth.signOut();

  const recoverPassword = (email) => auth.sendPasswordResetEmail(email);

  return { subscribe, unsubscribe, login, logout, recoverPassword };
};

const api = authAPI();

const initialState = {
  authState: authStates.loading,
  claims: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      if (action.payload) {
        state.user = action.payload.user;
        state.claims = action.payload.claims;
        state.authState = authStates.loggedIn;
      } else {
        state.user = undefined;
        state.claims = {};
        state.authState = authStates.loggedOut;
      }
    },
  },
});

const { updateAuth } = authSlice.actions;

export const selectAuthState = (state) => state.auth.authState;

export const selectUserClaims = (state) => state.auth.claims;

export const selectUserInfo = (state) => state.auth.user;

export const selectUserDoctorId = (state) => state.auth.claims.doctorId;

export const login = (email, password) => () => api.login(email, password);

export const logout = () => () => api.logout();

export const recoverPassword = (email) => () => api.recoverPassword(email);

export const observeAuthState = () => (dispatch) =>
  api.subscribe((x) => dispatch(updateAuth(x)));

export default authSlice.reducer;
