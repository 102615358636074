import _ from "lodash";
import { identity } from "lodash/fp";
import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import { db } from "../../services/firebase";
import List from "./List";
import Filter from "./Filter";
import {
  appointmentStatus,
  appointmentStatusDisplayOrder,
} from "../../util/appointmentStatus";
import { selectDoctors } from "../../redux/doctorsSlice";
import { selectProcedures } from "../../redux/proceduresSlice";
import { selectHealthInsurances } from "../../redux/healthInsurancesSlice";
import {
  observeAppointments,
  selectAppointments,
  selectAppointmentsStatus,
} from "../../redux/appointmentsSlice";
import { asyncStatus } from "../../util/helpers";
import { routes } from "../../util/routes";

export default function AppointmentsPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [records, setRecords] = useState(null);
  const [validRecordsCount, setValidRecordsCount] = useState("");
  const [filters, setFilters] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  const doctors = useSelector(selectDoctors);
  const procedures = useSelector(selectProcedures);
  const healthInsurances = useSelector(selectHealthInsurances);
  const allRecords = useSelector(selectAppointments);
  const loadingStatus = useSelector(selectAppointmentsStatus);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const findById = (list, id) => list.find((x) => x.id === id);

  const buildRecord = useCallback(
    (x) => ({
      ...x,
      doctor: findById(doctors, x.doctorId)?.name,
      procedure: findById(procedures, x.procedureId)?.description,
      healthInsurance: findById(healthInsurances, x.healthInsuranceId)
        ?.description,
    }),
    [doctors, procedures, healthInsurances]
  );

  const sortRecords = (a, b) => {
    if (a.status !== b.status) {
      return (
        appointmentStatusDisplayOrder.indexOf(a.status) -
        appointmentStatusDisplayOrder.indexOf(b.status)
      );
    } else if (a.arrivalTime === b.arrivalTime) {
      return a.createdDate - b.createdDate;
    } else {
      return a.arrivalTime - b.arrivalTime;
    }
  };

  const isValidAppointment = (x) =>
    x.status !== appointmentStatus.missed &&
    x.status !== appointmentStatus.canceled;

  useEffect(() => {
    if (!selectedDate) return;

    dispatch(observeAppointments(selectedDate.toDate()));
  }, [selectedDate, dispatch]);

  useEffect(() => {
    if (loadingStatus === asyncStatus.loading) {
      setValidRecordsCount(0);
      setRecords(null);
    } else {
      const items = _.chain(allRecords)
        .filter(filters)
        .map(buildRecord)
        .thru((x) => x.sort(sortRecords))
        .value();

      setRecords(items);
      setValidRecordsCount(_.filter(items, isValidAppointment).length);
    }
  }, [filters, loadingStatus, allRecords, buildRecord]);

  const handleUpdateStatus = async (id, status) => {
    const model = { status: status };

    if (
      status === appointmentStatus.present ||
      status === appointmentStatus.priority
    ) {
      model.arrivalTime = moment().toDate();
    } else if (status === appointmentStatus.scheduled) {
      model.arrivalTime = null;
    }

    await db.doc(`appointments/${id}`).update(model);
  };

  const handleFilter = useCallback(async (obj) => {
    setSelectedDate(obj.date);
    setFilters(
      _.chain(obj)
        .pick(["doctor", "procedure", "healthInsurance"])
        .thru((x) => ({
          doctorId: x.doctor,
          procedureId: x.procedure,
          healthInsuranceId: x.healthInsurance,
        }))
        .pickBy(identity)
        .value()
    );
  }, []);

  const handleAdd = useCallback(
    async (obj) => {
      history.push(
        routes.createAppointment.createLink(selectedDate.format("DD-MM-yyyy"))
      );
    },
    [history, selectedDate]
  );

  const handleEdit = useCallback(
    async (obj) => {
      history.push(routes.editAppointment.createLink(obj.id));
    },
    [history]
  );

  return (
    <Grid container spacing={2} direction={isMobile ? "column-reverse" : "row"}>
      <Grid item sm={true}>
        <List
          records={records}
          count={validRecordsCount}
          date={selectedDate}
          onUpdateStatus={handleUpdateStatus}
          onAdd={handleAdd}
          onEdit={handleEdit}
        />
      </Grid>
      <Grid item sm={"auto"}>
        <Filter onFilter={handleFilter} />
      </Grid>
    </Grid>
  );
}
