import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  EventNote as EventNoteIcon,
  Payment as PaymentIcon,
  People as PeopleIcon,
  ListAlt as ListAltIcon,
  Receipt as ReceiptIcon,
  AttachMoney as AttachMoneyIcon,
  Assessment as AssessmentIcon,
  LocalHospitalOutlined as LocalHospitalOutlinedIcon,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { isRouteAllowed, routes } from "../../util/routes";
import { useSelector } from "react-redux";
import { selectUserClaims } from "../../redux/authSlice";

const drawerWidth = 240;

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
});

export default function Menu({ isOpen, onClose }) {
  const classes = useStyles();

  const { role } = useSelector(selectUserClaims);

  const items = [
    {
      icon: <EventNoteIcon />,
      route: routes.appointments,
    },
    {
      icon: <LocalHospitalOutlinedIcon />,
      route: routes.doctors,
    },
    {
      icon: <ListAltIcon />,
      route: routes.procedures,
    },
    {
      icon: <PaymentIcon />,
      route: routes.healthInsurances,
    },
    {
      icon: <PeopleIcon />,
      route: routes.users,
    },
    {
      icon: <ReceiptIcon />,
      route: routes.paymentReport,
    },
    {
      icon: <AttachMoneyIcon />,
      route: routes.receivablesReport,
    },
    {
      icon: <AssessmentIcon />,
      route: routes.genericReport,
    },
  ];

  return (
    <Drawer
      className={classes.drawer}
      onClose={onClose}
      open={isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {items
            .filter((i) => isRouteAllowed(role, i.route))
            .map((item) => (
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                exact
                key={item.route.title}
                to={item.route.createLink()}
                onClick={onClose}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.route.title} />
              </ListItem>
            ))}
        </List>
      </div>
    </Drawer>
  );
}
