import React from "react";
import { TextField, Box } from "@material-ui/core";

export default function Input({
  label,
  error,
  disabled,
  select,
  children,
  value,
  onChange,
}) {
  return (
    <Box mb={2}>
      <TextField
        label={label}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        error={error}
        helperText={error && "Campo obrigatório."}
        select={select}
        disabled={disabled}
        children={children}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
}
