import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../util/helpers";
import { db } from "../services/firebase";

const doctorsAPI = () => {
  let subscription;

  const subscribe = (updateCallback) => {
    if (!subscription) {
      subscription = db
        .collection("doctors")
        .orderBy("name")
        .onSnapshot((snapshot) => {
          const items = snapshot.docs?.map((x) => x.data());
          updateCallback(items);
        });
    }
  };

  const unsubscribe = () => {
    if (subscription) {
      subscription();
    }
  };

  const archive = (id) => db.doc(`doctors/${id}`).update({ archived: true });

  const create = (model) => {
    const doc = db.collection(`doctors`).doc();

    return doc.set({
      ...model,
      id: doc.id,
      archived: false,
    });
  };

  const update = (id, model) => db.doc(`doctors/${id}`).update(model);

  return { subscribe, unsubscribe, archive, create, update };
};

const api = doctorsAPI();

const initialState = {
  items: [],
  status: asyncStatus.loading,
};

export const doctorsSlice = createSlice({
  name: "doctors",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload;
      state.status = asyncStatus.completed;
    },
  },
});

const { updateItems } = doctorsSlice.actions;

export const selectDoctors = (state) => state.doctors.items;

export const selectActiveDoctors = (state) =>
  state.doctors.items?.filter((x) => !x.archived);

export const findDoctorById = (id) => (dispatch, getState) =>
  getState().doctors.items?.find((x) => x.id === id);

export const observeDoctors = () => (dispatch) =>
  api.subscribe((x) => dispatch(updateItems(x)));

export const archiveDoctor = (id) => () => api.archive(id);

export const createDoctor = (model) => () => api.create(model);

export const updateDoctor = (id, model) => () => api.update(id, model);

export default doctorsSlice.reducer;
