import React from "react";
import { Box } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function DateInput({ label, error, disabled, onChange, value }) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box mb={2}>
        <KeyboardDatePicker
          format="DD/MM/yyyy"
          disableToolbar
          fullWidth
          label={label}
          onChange={onChange}
          value={value}
          autoOk
          InputAdornmentProps={{ position: "start" }}
          variant="inline"
          inputVariant="outlined"
          error={error}
          helperText={error && "Campo obrigatório."}
          disabled={disabled}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
}
