import React from "react";
import { Box, Chip, Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  date: {
    textAlign: "center",
    alignSelf: "center",
    fontSize: "1.1rem",
    fontWeight: 600,
    color: "#444444",
  },
  count: {
    alignSelf: "center",
  },
  chip: {
    background: "#f44336",
    color: "#FFFFFF",
    fontWeight: 600,
  },
}));

export default function ListHeader({ count, date, onAdd, viewOnly }) {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box className={classes.count}>
        <Chip label={count} className={classes.chip} />
      </Box>
      <Box flexGrow={1} className={classes.date}>
        {date.format("dddd, DD [de] MMMM [de] YYYY")}
      </Box>
      <Box>
        {!viewOnly && (
          <Button color="primary" variant="contained" onClick={onAdd}>
            Adicionar
          </Button>
        )}
      </Box>
    </Box>
  );
}
