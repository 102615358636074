import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import List from "./List";
import Form from "./Form";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Toast from "../../components/ui/Toast";
import { useDispatch, useSelector } from "react-redux";
import {
  archiveProcedure,
  createProcedure,
  updateProcedure,
  selectActiveProcedures,
} from "../../redux/proceduresSlice";

export default function ProceduresPage() {
  const dispatch = useDispatch();

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [showSavedAlert, setShowSavedAlert] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const records = useSelector(selectActiveProcedures);

  const onCancelSave = () => {
    setSelectedRecord(null);
  };

  const handleDeleteDialog = async (shouldDelete) => {
    if (shouldDelete) {
      dispatch(archiveProcedure(recordToDelete.id));
    }
    setRecordToDelete(null);
  };

  const onSave = (model) => {
    const action = model.id
      ? (m) => updateProcedure(model.id, m)
      : createProcedure;

    dispatch(action(model)).then(() => {
      setShowSavedAlert(true);
      setSelectedRecord(null);
    });
  };

  const handleCloseAlert = () => setShowSavedAlert(false);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction={isMobile ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={8}>
          <List
            records={records}
            onEdit={setSelectedRecord}
            onDelete={setRecordToDelete}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Form
            record={selectedRecord}
            onSave={onSave}
            onCancel={onCancelSave}
          />
        </Grid>
      </Grid>
      <DeleteDialog
        isOpen={Boolean(recordToDelete)}
        description={recordToDelete?.name}
        onClose={handleDeleteDialog}
      />
      <Toast
        isOpen={showSavedAlert}
        onClose={handleCloseAlert}
        description="Salvo com sucesso!"
      />
    </>
  );
}
