import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../util/helpers";
import { db } from "../services/firebase";

const healthInsurancesAPI = () => {
  let subscription;

  const subscribe = (updateCallback) => {
    if (!subscription) {
      subscription = db
        .collection("healthInsurances")
        .orderBy("description")
        .onSnapshot((snapshot) => {
          const items = snapshot.docs?.map((x) => x.data());
          updateCallback(items);
        });
    }
  };

  const unsubscribe = () => {
    if (subscription) {
      subscription();
    }
  };

  const archive = (id) =>
    db.doc(`healthInsurances/${id}`).update({ archived: true });

  const create = (model) => {
    const doc = db.collection(`healthInsurances`).doc();

    return doc.set({
      ...model,
      id: doc.id,
      archived: false,
    });
  };

  const update = (id, model) => db.doc(`healthInsurances/${id}`).update(model);

  return { subscribe, unsubscribe, archive, create, update };
};

const api = healthInsurancesAPI();

const initialState = {
  items: [],
  status: asyncStatus.loading,
};

export const healthInsurancesSlice = createSlice({
  name: "healthInsurances",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload;
      state.status = asyncStatus.completed;
    },
  },
});

const { updateItems } = healthInsurancesSlice.actions;

export const selectHealthInsurances = (state) => state.healthInsurances.items;

export const selectActiveHealthInsurances = (state) =>
  state.healthInsurances.items?.filter((x) => !x.archived);

export const observeHealthInsurances = () => (dispatch) =>
  api.subscribe((x) => dispatch(updateItems(x)));

export const archiveHealthInsurance = (id) => () => api.archive(id);

export const createHealthInsurance = (model) => () => api.create(model);

export const updateHealthInsurance = (id, model) => () => api.update(id, model);

export default healthInsurancesSlice.reducer;
