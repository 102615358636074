import React, { useEffect, useState, useCallback } from "react";
import { Card, CardContent, Button, Grid } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import "moment/locale/pt-br";
import { useDispatch, useSelector } from "react-redux";

import Input from "../../components/ui/Input";
import Select from "../../components/ui/Select";
import LoadingButton from "../../components/ui/LoadingButton";
import { appointmentStatus } from "../../util/appointmentStatus";
import DateInput from "../../components/ui/DateInput";
import { handleChange } from "../../util/helpers";
import MaskedInput from "../../components/ui/MaskedInput";
import { selectActiveDoctors } from "../../redux/doctorsSlice";
import { selectActiveProcedures } from "../../redux/proceduresSlice";
import { selectActiveHealthInsurances } from "../../redux/healthInsurancesSlice";
import {
  createAppointment,
  findAppointmentById,
  updateAppointment,
} from "../../redux/appointmentsSlice";
import { routes } from "../../util/routes";

moment.locale("pt-br"); // it is required to select default locale manually

export default function AppointmentFormPage() {
  const { id, selectedDate } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [canValidate, setCanValidate] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [record, setRecord] = useState();

  const doctors = useSelector(selectActiveDoctors);
  const procedures = useSelector(selectActiveProcedures);
  const healthInsurances = useSelector(selectActiveHealthInsurances);

  const [date, setDate] = useState(moment());
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [procedureId, setProcedureId] = useState("");
  const [healthInsuranceId, setHealthInsuranceId] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(findAppointmentById(id)).then(setRecord);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!id) {
      const obj = moment(selectedDate, "DD-MM-yyyy");
      setDate(obj.isValid() ? obj : moment());
    }
  }, [selectedDate, id]);

  useEffect(() => {
    const loadFromRecord = () => {
      setDate(moment(record.date));
      setName(record.name);
      setPhoneNumber(record.phoneNumber);
      setDoctorId(record.doctorId);
      setProcedureId(record.procedureId);
      setHealthInsuranceId(record.healthInsuranceId);
    };

    const getFirst = (x) => x[0]?.id || "";

    const loadDefault = () => {
      setDoctorId(getFirst(doctors));
      setProcedureId(getFirst(procedures));
      setHealthInsuranceId(getFirst(healthInsurances));
    };

    if (record) {
      loadFromRecord();
    } else {
      loadDefault();
    }
  }, [record, doctors, procedures, healthInsurances]);

  const isEditLimited = record && record.status !== appointmentStatus.scheduled;

  const removePhoneNumberMask = (str) => _.replace(str, /[_ ()-]/g, "");

  const isFieldDisabled = (rules) => isSendingForm || rules;

  const validateFields = useCallback(() => {
    return {
      date: !date || !date.isValid(),
      name: !name || name.trim().length <= 0,
      phoneNumber: _.chain(phoneNumber)
        .thru(removePhoneNumberMask)
        .thru((x) => !x || x.trim().length < 10)
        .value(),
    };
  }, [date, name, phoneNumber]);

  const isFormValid = () =>
    _.chain(validateFields())
      .values()
      .every((x) => !x)
      .value();

  const buildModel = () => ({
    date: date.startOf("day").toDate(),
    name: name.trim(),
    phoneNumber: removePhoneNumberMask(phoneNumber),
    doctorId,
    procedureId,
    healthInsuranceId,
  });

  const onSubmit = (event) => {
    event.preventDefault();
    setIsSendingForm(true);
    setCanValidate(true);

    if (!isFormValid()) {
      setIsSendingForm(false);
      return;
    }

    const action = record ? (m) => updateAppointment(id, m) : createAppointment;

    const model = buildModel();

    return dispatch(action(model)).then(() => {
      setIsSendingForm(false);
      history.push(routes.appointments.createLink());
    });
  };

  const onCancel = () => history.goBack();

  useEffect(() => {
    if (canValidate) {
      setErrors(validateFields());
    }
  }, [canValidate, date, name, phoneNumber, validateFields]);

  return (
    <Card>
      <CardContent>
        <form onSubmit={onSubmit}>
          <DateInput
            label="Data do Agendamento"
            onChange={setDate}
            value={date}
            error={errors.date}
            disabled={isFieldDisabled(Boolean(id))}
          />
          <Input
            label="Nome"
            value={name}
            onChange={handleChange(setName)}
            error={errors.name}
            disabled={isFieldDisabled()}
          />
          <MaskedInput
            label="Telefone"
            value={phoneNumber}
            onChange={handleChange(setPhoneNumber)}
            error={errors.phoneNumber}
            mask="(99) 99999-9999"
            disabled={isFieldDisabled()}
          />
          <Select
            label="Procedimento"
            value={procedureId}
            onChange={(val) => setProcedureId(val)}
            items={procedures}
            idKey="id"
            valueKey="description"
            disabled={isFieldDisabled(isEditLimited)}
          />
          <Select
            label="Médico"
            value={doctorId}
            onChange={(val) => setDoctorId(val)}
            items={doctors}
            idKey="id"
            valueKey="name"
            disabled={isFieldDisabled(isEditLimited)}
          />
          <Select
            label="Convênio"
            value={healthInsuranceId}
            onChange={(val) => setHealthInsuranceId(val)}
            items={healthInsurances}
            idKey="id"
            valueKey="description"
            disabled={isFieldDisabled(isEditLimited)}
          />
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              disabled={isFieldDisabled()}
              isLoading={isSendingForm}
            >
              Salvar
            </LoadingButton>

            <Button
              variant="contained"
              onClick={onCancel}
              disabled={isFieldDisabled()}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
