import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import { useDispatch } from "react-redux";
import { handleChange } from "../../util/helpers";
import { recoverPassword } from "../../redux/authSlice";
import LoadingButton from "../../components/ui/LoadingButton";
import Alert from "../../components/dialogs/Alert";
import { useHistory } from "react-router";
import { routes } from "../../util/routes";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: red[400],
  },
}));

export default function RecoverPasswordPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setIsSendingForm(true);
    setError(false);

    dispatch(recoverPassword(email))
      .then(() => setShowConfirmation(true))
      .catch(() => {
        setError(true);
        setIsSendingForm(false);
      });
  };

  const onCloseConfirmation = () => history.push(routes.login.createLink());

  const errorMessage = () => {
    if (error)
      return <small className={classes.error}>Email não encontrado.</small>;
  };

  return (
    <Container component="main" maxWidth="xs">

      <Alert
        title="Sucesso!"
        isOpen={showConfirmation}
        description="Foi enviado um email para sua caixa de email com um link para criar uma nova senha."
      >
        <Button onClick={onCloseConfirmation} color="primary">
          Fechar
        </Button>
      </Alert>

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recuperar Senha
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            autoComplete="email"
            id="email"
            name="email"
            autoFocus
            value={email}
            onChange={handleChange(setEmail)}
          />

          {errorMessage()}

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            isLoading={isSendingForm}
          >
            ENVIAR
          </LoadingButton>
        </form>
      </div>
    </Container>
  );
}
