import React from "react";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { observeDoctors } from "./redux/doctorsSlice.js";
import { observeProcedures } from "./redux/proceduresSlice";
import { observeHealthInsurances } from "./redux/healthInsurancesSlice";
import AppointmentSavedNotification from "./pages/appointmentForm/AppointmentSavedNotification";
import { observeAuthState, selectAuthState } from "./redux/authSlice";
import { authStates } from "./util/helpers";
import PageLoading from "./components/ui/PageLoading";

function Routes() {
  const authState = useSelector(selectAuthState);

  switch (authState) {
    case authStates.loggedIn:
      return <AuthenticatedRoutes />;
    case authStates.loggedOut:
      return <UnauthenticatedRoutes />;
    default:
    case authStates.loading:
      return <PageLoading />;
  }
}

function App() {
  const dispatch = useDispatch();

  dispatch(observeAuthState());
  dispatch(observeDoctors());
  dispatch(observeProcedures());
  dispatch(observeHealthInsurances());

  return (
    <>
      <AppointmentSavedNotification />
      <Routes />
    </>
  );
}

export default App;
