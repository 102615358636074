export const appointmentStatus = {
  scheduled: "Agendado",
  confirmed: "Confirmado",
  present: "Presente",
  priority: "Preferencial",
  canceled: "Cancelado",
  missed: "Faltou",
  seen: "Atendido",
};

export const appointmentStatusDisplayOrder = [
  appointmentStatus.priority,
  appointmentStatus.present,
  appointmentStatus.confirmed,
  appointmentStatus.scheduled,
  appointmentStatus.seen,
  appointmentStatus.missed,
  appointmentStatus.canceled,
];

export const getAppointmentStatusStates = (status) => {
  switch (status) {
    case appointmentStatus.confirmed:
      return [
        appointmentStatus.present,
        appointmentStatus.priority,
        appointmentStatus.scheduled,
        appointmentStatus.canceled,
        appointmentStatus.missed,
      ];

    case appointmentStatus.present:
      return [
        appointmentStatus.seen,
        appointmentStatus.scheduled,
        appointmentStatus.confirmed,
        appointmentStatus.canceled,
        appointmentStatus.missed,
      ];

    case appointmentStatus.priority:
      return [
        appointmentStatus.seen,
        appointmentStatus.scheduled,
        appointmentStatus.confirmed,
        appointmentStatus.canceled,
        appointmentStatus.missed,
      ];

    case appointmentStatus.seen:
      return [
        appointmentStatus.present,
        appointmentStatus.priority,
      ];

    case appointmentStatus.canceled:
      return [
        appointmentStatus.scheduled,
        appointmentStatus.confirmed,
        appointmentStatus.present,
        appointmentStatus.priority,
      ];

    case appointmentStatus.missed:
      return [
        appointmentStatus.scheduled,
        appointmentStatus.confirmed,
        appointmentStatus.present,
        appointmentStatus.priority,
      ];

    case appointmentStatus.scheduled:
    default:
      return [
        appointmentStatus.confirmed,
        appointmentStatus.present,
        appointmentStatus.priority,
        appointmentStatus.canceled,
        appointmentStatus.missed,
      ];
  }
};
