export const handleChange = (field) => (event) => {
  field(event.target.value);
};

export const asyncStatus = {
  loading: "loading",
  completed: "completed",
};

export const authStates = {
  loading: "loading",
  loggedIn: "loggedIn",
  loggedOut: "loggedOut",
};

export const authRoles = {
  admin: "ADMIN",
  doctor: "DOCTOR",
  secretary: "SECRETARY",
};

export const authRolesDisplay = {
  ADMIN: "Admin",
  DOCTOR: "Médico",
  SECRETARY: "Secretária",
};
