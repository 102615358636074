import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";

import _ from "lodash";
import Select from "../../components/ui/Select";
import DateInput from "../../components/ui/DateInput";
import { selectActiveDoctors } from "../../redux/doctorsSlice";
import { selectActiveProcedures } from "../../redux/proceduresSlice";
import { selectActiveHealthInsurances } from "../../redux/healthInsurancesSlice";
import { appointmentStatus } from "../../util/appointmentStatus";
import LoadingButton from "../../components/ui/LoadingButton";

export default function Filter({ onFilter }) {
  const all = "0";
  const doctors = useSelector(selectActiveDoctors);
  const procedures = useSelector(selectActiveProcedures);
  const healthInsurances = useSelector(selectActiveHealthInsurances);

  const statusList = _.map(appointmentStatus, (x) => ({ id: x, value: x }));

  const [startDate, setStartDate] = useState(moment().startOf("month"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [doctor, setDoctor] = useState(all);
  const [procedure, setProcedure] = useState(all);
  const [healthInsurance, setHealthInsurance] = useState(all);
  const [status, setStatus] = useState(all);

  const handleFilter = () => {
    if (!startDate.isValid() || !endDate.isValid()) return;

    const filters = {
      startDate,
      endDate,
    };

    if (doctor !== all) {
      filters.doctor = doctor;
    }

    if (procedure !== all) {
      filters.procedure = procedure;
    }

    if (healthInsurance !== all) {
      filters.healthInsurance = healthInsurance;
    }

    if (status !== all) {
      filters.status = status;
    }

    onFilter(filters);
  };

  return (
    <Card>
      <CardContent>
        <h3>Filtros</h3>

        <DateInput
          label="Data Inicial"
          onChange={setStartDate}
          value={startDate}
        />

        <DateInput label="Data Final" onChange={setEndDate} value={endDate} />

        <Select
          label="Procedimento"
          value={procedure}
          onChange={setProcedure}
          items={procedures}
          idKey="id"
          valueKey="description"
          all={true}
        />

        <Select
          label="Médico"
          value={doctor}
          onChange={setDoctor}
          items={doctors}
          idKey="id"
          valueKey="name"
          all={true}
        />

        <Select
          label="Convênio"
          value={healthInsurance}
          onChange={setHealthInsurance}
          items={healthInsurances}
          idKey="id"
          valueKey="description"
          all={true}
        />

        <Select
          label="Situação"
          value={status}
          onChange={setStatus}
          items={statusList}
          idKey="id"
          valueKey="value"
          all={true}
        />

        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          isLoading={false}
          onClick={handleFilter}
        >
          Pesquisar
        </LoadingButton>
      </CardContent>
    </Card>
  );
}
