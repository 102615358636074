import React from "react";
import { Box, FormControlLabel, Checkbox } from "@material-ui/core";

export default function Input({ label, disabled, value, onChange }) {
  return (
    <Box mb={2}>
      <FormControlLabel
        control={
          <Checkbox checked={value} onChange={onChange} disabled={disabled} />
        }
        label={label}
      />
    </Box>
  );
}
