import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { Menu as MenuIcon, AccountCircle } from "@material-ui/icons";
import { Route, Switch } from "react-router-dom";
import { authenticatedRoutes } from "../../util/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUserInfo } from "../../redux/authSlice";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 200,
  },
}));

export default function Header({ onToggleMenu }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { email } = useSelector(selectUserInfo);

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    closeMenu();
    dispatch(logout());
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={onToggleMenu}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          <Switch>
            {authenticatedRoutes.map((x, i) => (
              <Route key={i} path={x.path} children={x.title} />
            ))}
          </Switch>
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={openMenu}
            color="inherit"
            ref={anchorEl}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem disabled={true}>{email}</MenuItem>
            <Divider />
            <MenuItem onClick={closeMenu} disabled={true}>
              Mudar Senha
            </MenuItem>
            <MenuItem onClick={onLogout}>Sair</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
