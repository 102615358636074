import { Grid } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { identity } from "lodash/fp";
import { listAppointmentsFromInterval } from "../../redux/appointmentsSlice";
import moment from "moment";
import _ from "lodash";
import { selectDoctors } from "../../redux/doctorsSlice";
import { selectHealthInsurances } from "../../redux/healthInsurancesSlice";
import { selectProcedures } from "../../redux/proceduresSlice";
import Filter from "./Filter";
import List from "./List";

export default function GenericReportPage() {
  const dispatch = useDispatch();

  const doctors = useSelector(selectDoctors);
  const procedures = useSelector(selectProcedures);
  const healthInsurances = useSelector(selectHealthInsurances);

  const [records, setRecords] = useState();
  const [isShowingList, setIsShowingList] = useState(false);

  const findById = (list, id) => list.find((x) => x.id === id);

  const handleFilter = useCallback(
    (obj) => {
      const buildRecord = (x) => ({
        ...x,
        doctor: findById(doctors, x.doctorId)?.name,
        procedure: findById(procedures, x.procedureId)?.description,
        healthInsurance: findById(healthInsurances, x.healthInsuranceId)
          ?.description,
        dateFormatted: moment(x.date).format("DD/MM/YYYY"),
      });

      const sortRecords = (a, b) => a.date - b.date;

      const filters = _.chain(obj)
        .pick(["doctor", "procedure", "healthInsurance", "status"])
        .thru((x) => ({
          doctorId: x.doctor,
          procedureId: x.procedure,
          healthInsuranceId: x.healthInsurance,
          status: x.status,
        }))
        .pickBy(identity)
        .value();

      setIsShowingList(true);
      setRecords(undefined);

      return dispatch(listAppointmentsFromInterval(obj.startDate, obj.endDate))
        .then((x) =>
          _.chain(x)
            .filter(filters)
            .map(buildRecord)
            .thru((x) => x.sort(sortRecords))
            .value()
        )
        .then(setRecords);
    },
    [dispatch, doctors, procedures, healthInsurances]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Filter onFilter={handleFilter} />
      </Grid>
      <Grid item xs={12}>
        {isShowingList && <List records={records} />}
      </Grid>
    </Grid>
  );
}
