import {
  Card,
  CardContent,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAppointmentsOfMonth } from "../../redux/appointmentsSlice";
import moment from "moment";
import _ from "lodash";
import { selectHealthInsurances } from "../../redux/healthInsurancesSlice";
import { selectProcedures } from "../../redux/proceduresSlice";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { appointmentStatus } from "../../util/appointmentStatus";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

export default function ReceivablesReportPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const procedures = useSelector(selectProcedures);
  const healthInsurances = useSelector(selectHealthInsurances);

  const [records, setRecords] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    const formatItem = (x) => ({
      ...x,
      healthInsurance: _.chain(healthInsurances)
        .find({ id: x.healthInsuranceId })
        .get("description")
        .value(),
      procedure: _.chain(procedures)
        .find({ id: x.procedureId })
        .get("description")
        .value(),
    });

    if (
      healthInsurances.length &&
      procedures.length &&
      selectedDate.isValid()
    ) {
      dispatch(listAppointmentsOfMonth(selectedDate))
        .then((items) =>
          _.chain(items)
            .filter({ status: appointmentStatus.seen })
            .map(formatItem)
            .groupBy("procedure")
            .mapValues((x) => _.countBy(x, "healthInsurance"))
            .value()
        )
        .then(setRecords);
    }
  }, [dispatch, healthInsurances, procedures, selectedDate]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <h3>Filtros</h3>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format="MM/yyyy"
                views={["month", "year"]}
                fullWidth
                label="Mês"
                autoOk
                InputAdornmentProps={{ position: "start" }}
                variant="inline"
                inputVariant="outlined"
                onChange={setSelectedDate}
                value={selectedDate}
                error={!selectedDate.isValid()}
              />
            </MuiPickersUtilsProvider>
          </CardContent>
        </Card>
      </Grid>
      {_.toPairs(records).map(([procedure, items]) => (
        <Grid item xs={12} md={6} key={procedure}>
          <Paper>
            <Grid container spacing={3} className={classes.cardHeader}>
              <Grid item xs={10}>
                <Typography variant="h6" component="h6">
                  {procedure}
                </Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <Typography variant="h6" component="h6">
                  {_.chain(items).values().sum().value()}
                </Typography>
              </Grid>
            </Grid>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {_.toPairs(items).map(([healthInsurance, quantity]) => (
                    <TableRow key={healthInsurance}>
                      <TableCell component="th" scope="row">
                        {healthInsurance}
                      </TableCell>
                      <TableCell align="right">{quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
