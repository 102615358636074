import React from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";

export default function ListLoading() {
  return (
    <Grid container justify="center" alignItems="center">
      <Box m={5}>
        <CircularProgress />
      </Box>
    </Grid>
  );
}
