import {
  Card,
  CardContent,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAppointmentsOfMonth } from "../../redux/appointmentsSlice";
import moment from "moment";
import _ from "lodash";
import { selectDoctors } from "../../redux/doctorsSlice";
import { selectProcedures } from "../../redux/proceduresSlice";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { appointmentStatus } from "../../util/appointmentStatus";
import { selectHealthInsurances } from "../../redux/healthInsurancesSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0),
  },
}));

export default function PaymentReportPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const doctors = useSelector(selectDoctors);
  const procedures = useSelector(selectProcedures);
  const healthInsurances = useSelector(selectHealthInsurances);

  const [records, setRecords] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    const addDoctorAndProcedureNames = (x) => ({
      ...x,
      doctor: _.chain(doctors).find({ id: x.doctorId }).get("name").value(),
      procedure: _.chain(procedures)
        .find({ id: x.procedureId })
        .get("description")
        .value(),
      monthlyPayment: _.chain(healthInsurances)
        .find({ id: x.healthInsuranceId })
        .get("monthlyPayment")
        .value(),
    });

    if (doctors.length && procedures.length && selectedDate.isValid()) {
      dispatch(listAppointmentsOfMonth(selectedDate))
        .then((items) =>
          _.chain(items)
            .filter({ status: appointmentStatus.seen })
            .map(addDoctorAndProcedureNames)
            .filter({ monthlyPayment: true })
            .groupBy("doctor")
            .mapValues((x) => _.countBy(x, "procedure"))
            .value()
        )
        .then(setRecords);
    }
  }, [dispatch, doctors, procedures, healthInsurances, selectedDate]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <h3>Filtros</h3>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format="MM/yyyy"
                views={["month", "year"]}
                fullWidth
                label="Mês"
                autoOk
                InputAdornmentProps={{ position: "start" }}
                variant="inline"
                inputVariant="outlined"
                onChange={setSelectedDate}
                value={selectedDate}
                error={!selectedDate.isValid()}
              />
            </MuiPickersUtilsProvider>
          </CardContent>
        </Card>
      </Grid>
      {_.toPairs(records).map(([doctor, items]) => (
        <Grid item xs={12} md={6} key={doctor}>
          <Paper>
            <Toolbar className={classes.root}>
              <Typography variant="h6" id="tableTitle" component="div">
                {doctor}
              </Typography>
            </Toolbar>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {_.toPairs(items).map(([procedure, quantity]) => (
                    <TableRow key={procedure}>
                      <TableCell component="th" scope="row">
                        {procedure}
                      </TableCell>
                      <TableCell align="right">{quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
