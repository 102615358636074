import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../util/helpers";
import { db, serverTimestamp } from "../services/firebase";
import moment from "moment";
import { appointmentStatus } from "../util/appointmentStatus";

const appointmentsAPI = () => {
  let subscription;

  const formatAppointment = (model) => {
    return {
      ...model,
      date: +moment(model.date.toDate()),
      createdDate: model.createdDate ? +moment(model.createdDate.toDate()) : 0,
      arrivalTime: model.arrivalTime ? +moment(model.arrivalTime.toDate()) : 0,
    };
  };

  const getDoc = (doc) =>
    doc
      .get()
      .then((x) => x.data())
      .then(formatAppointment);

  const subscribe = (date, updateCallback) => {
    unsubscribe();

    subscription = db
      .collection("appointments")
      .where("date", "==", date)
      .onSnapshot((snapshot) => {
        const items = snapshot.docs.map((x) => x.data()).map(formatAppointment);
        updateCallback({ items, date: +moment(date) });
      });
  };

  const unsubscribe = () => {
    if (subscription) {
      subscription();
    }
  };

  const findById = (id) =>
    db
      .doc(`/appointments/${id}`)
      .get()
      .then((doc) => (doc.exists ? formatAppointment(doc.data()) : undefined));

  const listFromInterval = (startDate, endDate) =>
    db
      .collection(`/appointments`)
      .where("date", ">=", startDate.toDate())
      .where("date", "<=", endDate.toDate())
      .get()
      .then((snapshot) =>
        snapshot.docs.map((x) => formatAppointment(x.data()))
      );

  const create = (model) => {
    const doc = db.collection(`appointments`).doc();

    return doc
      .set({
        ...model,
        id: doc.id,
        createdDate: serverTimestamp(),
        status: appointmentStatus.scheduled,
      })
      .then(() => doc.get())
      .then((x) => x.data())
      .then(formatAppointment);
  };

  const update = (id, model) => {
    const doc = db.doc(`appointments/${id}`);

    return doc.update(model).then(() => getDoc(doc));
  };

  return {
    subscribe,
    unsubscribe,
    findById,
    create,
    update,
    listFromInterval,
  };
};

const api = appointmentsAPI();

const initialState = {
  items: [],
  status: asyncStatus.loading,
};

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload.items;
      state.selectedDate = action.payload.date;
      state.status = asyncStatus.completed;
    },
    resetItems: (state) => {
      state.items = [];
      state.status = asyncStatus.loading;
    },
    notifyAppointment: (state, action) => {
      state.notify = action.payload;
    },
    appointmentNotified: (state) => {
      state.notify = undefined;
    },
  },
});

const { updateItems, resetItems, notifyAppointment } =
  appointmentsSlice.actions;

export const { appointmentNotified } = appointmentsSlice.actions;

export const selectAppointments = (state) => state.appointments.items;

export const selectAppointmentsStatus = (state) => state.appointments.status;

export const selectAppointmentToNotify = (state) => state.appointments.notify;

export const observeAppointments = (date) => (dispatch, getState) => {
  const selectedDate = getState().appointments.selectedDate;
  if (selectedDate !== +moment(date)) {
    dispatch(resetItems());
    api.subscribe(date, (x) => dispatch(updateItems(x)));
  }
};

export const findAppointmentById = (id) => (dispatch, getState) => {
  const items = getState().appointments.items;
  const item = items.find((x) => x.id === id);
  return item ? Promise.resolve(item) : api.findById(id);
};

export const updateAppointment = (id, model) => (dispatch) => {
  return api.update(id, model).then((record) => {
    dispatch(notifyAppointment(record));
    return record;
  });
};

export const createAppointment = (model) => (dispatch) => {
  return api.create(model).then((record) => {
    dispatch(notifyAppointment(record));
    return record;
  });
};

export const listAppointmentsOfMonth = (date) => () => {
  return api.listFromInterval(date.clone().startOf("month"), date.clone().endOf("month"));
};

export const listAppointmentsFromInterval = (startDate, endDate) => () => {
  return api.listFromInterval(startDate, endDate);
};

export default appointmentsSlice.reducer;
