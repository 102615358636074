import React from "react";
import { Card, CardContent, Button, Grid } from "@material-ui/core";
import Input from "../../components/ui/Input";
import LoadingButton from "../../components/ui/LoadingButton";
import { useEffect } from "react";
import {
  stringNotEmptyOrWhiteSpace,
  useForm,
} from "../../hooks/useForm";

export default function Form({ record, onSave, onCancel }) {
  const {
    handleSubmit,
    getFieldValue,
    handleInputChange,
    isSendingForm,
    resetFields,
    initializeFields,
    configureValidations,
    validate,
    errors,
  } = useForm();

  useEffect(() => {
    configureValidations([
      validate("name", [stringNotEmptyOrWhiteSpace]),
    ]);
  }, [configureValidations, validate]);

  useEffect(() => {
    initializeFields(record);
  }, [record, initializeFields]);

  const onSubmit = (data) => {
    const obj = { ...record, ...data };
    Promise.resolve(onSave(obj)).then(() => resetFields());
  };

  const cancel = () => {
    resetFields();
    onCancel();
  };

  return (
    <Card>
      <CardContent>
        <h3>{record ? "Editar" : "Adicionar"}</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Nome"
            value={getFieldValue("name")}
            onChange={handleInputChange("name")}
            error={errors.name}
            disabled={isSendingForm}
          />
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              isLoading={isSendingForm}
            >
              Salvar
            </LoadingButton>
            <Button
              variant="contained"
              onClick={cancel}
              disabled={isSendingForm}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
