import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppointmentToNotify,
  appointmentNotified,
} from "../../redux/appointmentsSlice";
import { findDoctorById } from "../../redux/doctorsSlice";
import { findProcedureById } from "../../redux/proceduresSlice";
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AppointmentSavedNotification() {
  const dispatch = useDispatch();

  const appointment = useSelector(selectAppointmentToNotify);
  const [notification, setNotification] = useState();

  useEffect(() => {
    if (appointment) {
      setNotification({
        ...appointment,
        doctor: dispatch(findDoctorById(appointment.doctorId))?.name,
        procedure: dispatch(findProcedureById(appointment.procedureId))
          ?.description,
        date: moment(appointment.date).format("DD/MM/YYYY (dddd)"),
      });
      dispatch(appointmentNotified);
    }
  }, [appointment, dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification(null);
  };

  return (
    <Snackbar
      open={Boolean(notification)}
      autoHideDuration={8000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Alert onClose={handleClose} severity="success">
        <b>{notification?.procedure}</b> marcado(a) para{" "}
        <b>{notification?.name}</b> no dia <b>{notification?.date}</b> com
        <b> Dr. {notification?.doctor}</b>.
      </Alert>
    </Snackbar>
  );
}
