import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import { useEffect } from "react";
import {
  appointmentStatus,
  getAppointmentStatusStates,
} from "../../util/appointmentStatus";
import {
  red,
  blue,
  pink,
  green,
  deepPurple,
  grey,
  cyan,
  orange,
} from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "150px",
    justifyContent: "left",
    color: "#FFFFFF",
  },
  buttonViewOnly: {
    minWidth: "120px",
    justifyContent: "center",
    color: "#FFFFFF",
  },
}));

export default function StatusButton({ status, onUpdatedStatus, viewOnly }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setStatusList(getAppointmentStatusStates(status));
  }, [status]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStaus = (status) => {
    setAnchorEl(null);
    onUpdatedStatus(status);
  };

  const getColor = (status) => {
    switch (status) {
      case appointmentStatus.confirmed:
        return pink[500];

      case appointmentStatus.present:
        return green[500];

      case appointmentStatus.priority:
        return cyan[700];

      case appointmentStatus.seen:
        return deepPurple[500];

      case appointmentStatus.billed:
        return grey[500];

      case appointmentStatus.paid:
        return grey[800];

      case appointmentStatus.canceled:
        return red[500];

      case appointmentStatus.missed:
        return orange[500];

      case appointmentStatus.scheduled:
      default:
        return blue[500];
    }
  };

  return (
    <>
      {viewOnly ? (
        <Button
          variant="contained"
          size="small"
          className={classes.buttonViewOnly}
          disableRipple={true}
          style={{ background: getColor(status) }}
        >
          {status}
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.button}
          style={{ background: getColor(status) }}
          startIcon={<ArrowDropDownIcon />}
        >
          {status}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusList.map((item) => (
          <MenuItem key={item} onClick={() => handleUpdateStaus(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
