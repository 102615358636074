import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar } from "@material-ui/core";
import { useSelector } from "react-redux";

import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import { authenticatedRoutes, isRouteAllowed } from "./util/routes";
import { selectUserClaims } from "./redux/authSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));

export default function AuthenticatedRoutes() {
  const classes = useStyles();

  const { role } = useSelector(selectUserClaims);

  const [menuOpen, setMenuOpen] = useState(false);

  const createRoute = (route, index) => {
    return isRouteAllowed(role, route) ? (
      <Route
        key={index}
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ) : undefined;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BrowserRouter>
        <Header onToggleMenu={() => setMenuOpen(!menuOpen)} />

        <Menu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />

        <main className={classes.container}>
          <Toolbar />
          <Switch>
            {authenticatedRoutes.map(createRoute)}
            <Redirect to="/" />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
}
