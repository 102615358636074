import firebase from "firebase";

const config = {
  apiKey: "AIzaSyD4SzUEO_sFVvhRH5EnF9E57Qp_YzMPf0c",
  authDomain: "cardioexames-36911.firebaseapp.com",
  databaseURL: "https://cardioexames-36911.firebaseio.com",
  projectId: "cardioexames-36911",
  storageBucket: "cardioexames-36911.appspot.com",
  messagingSenderId: "1056627617655",
  appId: "1:1056627617655:web:230e4f73e5fc4b5eca3b72",
};
firebase.initializeApp(config);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const api = firebase.functions();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

// firebase.functions().useEmulator("localhost", "5001");
