import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import List from "./List";
import Form from "./Form";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  disableUser,
  enableUser,
  listUsers,
  selectUsers,
} from "../../redux/usersSlice";
import { useEffect } from "react";
import { selectDoctors } from "../../redux/doctorsSlice";

export default function UsersPage() {
  const dispatch = useDispatch();

  const [records, setRecords] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const users = useSelector(selectUsers);
  const doctors = useSelector(selectDoctors);

  useEffect(() => {
    dispatch(listUsers);
  }, [dispatch]);

  useEffect(() => {
    const items = users?.map((x) => ({
      ...x,
      doctor: doctors.find((d) => d.id === x.doctorId)?.name ?? "--",
    }));
    setRecords(items);
  }, [users, doctors]);

  const handleEnableUser = (user) => dispatch(enableUser(user.id));

  const handleDisableUser = (user) => dispatch(disableUser(user.id));

  const handleSave = (model) => dispatch(createUser(model));

  return (
    <>
      <Grid
        container
        spacing={2}
        direction={isMobile ? "column-reverse" : "row"}
      >
        <Grid item xs={12} md={8}>
          <List
            records={records}
            onEnable={handleEnableUser}
            onDisable={handleDisableUser}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Form onSave={handleSave} />
        </Grid>
      </Grid>
    </>
  );
}
