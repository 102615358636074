import React from "react";
import { TextField, Box } from "@material-ui/core";
import ReactInputMask from "react-input-mask";

export default function MaskedInput({
  label,
  error,
  disabled,
  value,
  onChange,
  mask,
}) {
  return (
    <Box mb={2}>
      <ReactInputMask
        mask={mask}
        disabled={disabled}
        value={value}
        onChange={onChange}
        children={() => (
          <TextField
            label={label}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
            error={error}
            helperText={error && "Campo obrigatório."}
          />
        )}
      />
    </Box>
  );
}
