import { configureStore } from "@reduxjs/toolkit";
import doctorsReducer from "./doctorsSlice";
import proceduresReducer from "./proceduresSlice";
import healthInsurancesReducer from "./healthInsurancesSlice";
import appointmentsReducer from "./appointmentsSlice";
import usersReducer from "./usersSlice";
import authReducer from "./authSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    doctors: doctorsReducer,
    procedures: proceduresReducer,
    healthInsurances: healthInsurancesReducer,
    appointments: appointmentsReducer,
    users: usersReducer,
  },
});
