import React from "react";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import ListLoading from "../../components/ui/ListLoading";
import ListHeader from "./ListHeader";
import StatusButton from "./StatusButton";
import { selectUserClaims } from "../../redux/authSlice";
import { authRoles } from "../../util/helpers";
import { useSelector } from "react-redux";

export default function List({
  records,
  count,
  date,
  onAdd,
  onEdit,
  onUpdateStatus,
}) {
  const { role } = useSelector(selectUserClaims);

  const viewOnly = role === authRoles.doctor;

  const formatPhoneNumber = (x) =>
    x.length === 10
      ? `(${x.slice(0, 2)}) ${x.slice(2, 6)}-${x.slice(6)}`
      : `(${x.slice(0, 2)}) ${x.slice(2, 7)}-${x.slice(7)}`;

  const listItems = () => (
    <TableBody>
      {records.map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            <StatusButton
              viewOnly={viewOnly}
              status={item.status}
              onUpdatedStatus={(status) => onUpdateStatus(item.id, status)}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {item.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatPhoneNumber(item.phoneNumber)}
          </TableCell>
          {!viewOnly && (
            <TableCell component="th" scope="row">
              {item.doctor}
            </TableCell>
          )}
          <TableCell component="th" scope="row">
            {item.procedure}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.healthInsurance}
          </TableCell>
          {!viewOnly && (
            <TableCell align="right">
              <IconButton onClick={() => onEdit(item)} size="small">
                <EditIcon color="primary" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );

  const noItems = () => (
    <center>
      <h4>Nenhum agendamento para o filtro selecionado.</h4>
    </center>
  );

  return (
    <Card>
      <CardContent>
        {records && (
          <>
            <ListHeader count={count} date={date} onAdd={onAdd} viewOnly={viewOnly} />

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Situação</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Paciente</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Telefone</strong>
                  </TableCell>
                  {!viewOnly && (
                    <TableCell>
                      <strong>Médico</strong>
                    </TableCell>
                  )}
                  <TableCell>
                    <strong>Procedimento</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Convênio</strong>
                  </TableCell>
                  {!viewOnly && <TableCell />}
                </TableRow>
              </TableHead>
              {records.length > 0 && listItems()}
            </Table>

            {records.length === 0 && noItems()}
          </>
        )}

        {!records && <ListLoading />}
      </CardContent>
    </Card>
  );
}
