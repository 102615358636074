import React from "react";
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import ListLoading from "../../components/ui/ListLoading";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import { authRolesDisplay } from "../../util/helpers";

const useStyles = makeStyles({
  activateButton: {
    color: green[600],
    borderColor: green[600],
  },
});

export default function List({ records, onDisable, onEnable }) {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        {records && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Nome</strong>
                </TableCell>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Acesso</strong>
                </TableCell>
                <TableCell>
                  <strong>Médico</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.email}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {authRolesDisplay[item.role]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.doctor}
                  </TableCell>
                  <TableCell align="center">
                    {item.disabled ? (
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.activateButton}
                        onClick={() => onEnable(item)}
                      >
                        DESBLOQUEAR
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => onDisable(item)}
                      >
                        BLOQUEAR
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {!records && <ListLoading />}
      </CardContent>
    </Card>
  );
}
