import React from "react";
import { Button, Box, CircularProgress } from "@material-ui/core";

export default function LoadingButton(props) {
  const { isLoading, children } = props;

  const filteredProps = { ...props };
  delete filteredProps["isLoading"];
  delete filteredProps["children"];
  
  return (
    <Button {...filteredProps}>
      {children}
      {isLoading && (
        <Box ml={1}>
          <CircularProgress color="secondary" size={16} />
        </Box>
      )}
    </Button>
  );
}
