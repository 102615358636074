import { createSlice } from "@reduxjs/toolkit";
import { asyncStatus } from "../util/helpers";
import { api } from "../services/firebase";

const listUserApi = api.httpsCallable("listUsers");
const disableUserApi = api.httpsCallable("disableUser");
const createUserApi = api.httpsCallable("createUser");

const initialState = {
  items: [],
  status: asyncStatus.loading,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateItems: (state, action) => {
      state.items = action.payload;
      state.status = asyncStatus.completed;
    },
  },
});

const { updateItems } = usersSlice.actions;

export const selectUsers = (state) => state.users.items;

export const listUsers = (dispatch) =>
  listUserApi().then(({ data }) => dispatch(updateItems(data)));

export const enableUser = (id) => (dispatch) =>
  disableUserApi({ id, disabled: false }).then(() => listUsers(dispatch));

export const disableUser = (id) => (dispatch) =>
  disableUserApi({ id, disabled: true }).then(() => listUsers(dispatch));

export const createUser = (model) => (dispatch) =>
  createUserApi(model).then(() => listUsers(dispatch));

export default usersSlice.reducer;
