import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@material-ui/core";
import ListLoading from "../../components/ui/ListLoading";
import StatusButton from "../appointments/StatusButton";

export default function List({ records }) {
  const formatPhoneNumber = (x) =>
    x.length === 10
      ? `(${x.slice(0, 2)}) ${x.slice(2, 6)}-${x.slice(6)}`
      : `(${x.slice(0, 2)}) ${x.slice(2, 7)}-${x.slice(7)}`;

  const listItems = () => (
    <TableBody>
      {records.map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            <StatusButton viewOnly={true} status={item.status} />
          </TableCell>
          <TableCell component="th" scope="row">
            {item.dateFormatted}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatPhoneNumber(item.phoneNumber)}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.doctor}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.procedure}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.healthInsurance}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const noItems = () => (
    <center>
      <h4>Nenhum agendamento para o filtro selecionado.</h4>
    </center>
  );

  return (
    <Card>
      {records && <CardHeader subheader={`${records.length} registros`} />}
      <CardContent>
        {records && (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Situação</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Data</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Paciente</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Telefone</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Médico</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Procedimento</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Convênio</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              {records.length > 0 && listItems()}
            </Table>

            {records.length === 0 && noItems()}
          </>
        )}

        {!records && <ListLoading />}
      </CardContent>
    </Card>
  );
}
