import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import { useDispatch } from "react-redux";
import { handleChange } from "../../util/helpers";
import { login } from "../../redux/authSlice";
import LoadingButton from "../../components/ui/LoadingButton";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: red[400],
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setIsSendingForm(true);
    setError(false);

    dispatch(login(email, password)).catch(() => {
      setError(true);
      setIsSendingForm(false);
    });
  };

  const errorMessage = () => {
    if (error)
      return (
        <small className={classes.error}>Usuário ou senha incorretos.</small>
      );
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Acesso Restrito
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email"
            autoComplete="email"
            id="email"
            name="email"
            autoFocus
            value={email}
            onChange={handleChange(setEmail)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handleChange(setPassword)}
          />

          {errorMessage()}

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            isLoading={isSendingForm}
          >
            Entrar
          </LoadingButton>
          <Link to="/recuperar-senha">Esqueceu a senha?</Link>
        </form>
      </div>
    </Container>
  );
}
