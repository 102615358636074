import React from "react";
import {
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import ListLoading from "../../components/ui/ListLoading";

export default function List({ records, onDelete, onEdit }) {
  return (
    <Card>
      <CardContent>
        {records && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Nome</strong>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => onEdit(item)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => onDelete(item)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {!records && <ListLoading />}
      </CardContent>
    </Card>
  );
}
