import React from "react";
import { Card, CardContent, Button, Grid } from "@material-ui/core";
import Input from "../../components/ui/Input";
import LoadingButton from "../../components/ui/LoadingButton";
import { useEffect, useCallback } from "react";
import {
  emailFormat,
  required,
  stringNotEmptyOrWhiteSpace,
  useForm,
} from "../../hooks/useForm";
import Select from "../../components/ui/Select";
import { authRoles, authRolesDisplay } from "../../util/helpers";
import { useSelector } from "react-redux";
import { selectActiveDoctors } from "../../redux/doctorsSlice";

export default function Form({ record, onSave }) {
  const {
    handleSubmit,
    getFieldValue,
    setFieldValue,
    handleInputChange,
    isSendingForm,
    resetFields,
    initializeFields,
    configureValidations,
    validate,
    errors,
  } = useForm();

  const doctors = useSelector(selectActiveDoctors);

  const validateDoctor = useCallback(
    (val) => getFieldValue("role") !== authRoles.doctor || !!val,
    [getFieldValue]
  );

  useEffect(() => {
    configureValidations([
      validate("name", [stringNotEmptyOrWhiteSpace]),
      validate("email", [emailFormat]),
      validate("role", [required]),
      validate("doctorId", [validateDoctor]),
    ]);
  }, [configureValidations, validate, validateDoctor]);

  useEffect(() => {
    initializeFields(record);
  }, [record, initializeFields]);

  const onSubmit = (data) =>
    Promise.resolve(onSave(data)).then(() => resetFields());

  const rolesList = [
    { key: authRoles.secretary, value: authRolesDisplay[authRoles.secretary] },
    { key: authRoles.doctor, value: authRolesDisplay[authRoles.doctor] },
    { key: authRoles.admin, value: authRolesDisplay[authRoles.admin] },
  ];

  const isRoleDoctor = getFieldValue("role") === authRoles.doctor;

  return (
    <Card>
      <CardContent>
        <h3>Adicionar</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Nome"
            value={getFieldValue("name")}
            onChange={handleInputChange("name")}
            error={errors.name}
            disabled={isSendingForm}
          />
          <Input
            label="Email"
            value={getFieldValue("email")}
            onChange={handleInputChange("email")}
            error={errors.email}
            disabled={isSendingForm}
          />
          <Select
            label="Acesso"
            value={getFieldValue("role")}
            onChange={setFieldValue("role")}
            error={errors.role}
            items={rolesList}
            idKey="key"
            valueKey="value"
            disabled={isSendingForm}
          />
          {isRoleDoctor && (
            <Select
              label="Médico"
              value={getFieldValue("doctorId")}
              onChange={setFieldValue("doctorId")}
              error={errors.doctorId}
              items={doctors}
              idKey="id"
              valueKey="name"
              disabled={isSendingForm}
            />
          )}
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              isLoading={isSendingForm}
            >
              Salvar
            </LoadingButton>
            <Button
              variant="contained"
              onClick={resetFields}
              disabled={isSendingForm}
            >
              Cancelar
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
