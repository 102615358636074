import { CssBaseline } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { unauthenticatedRoutes } from "./util/routes";

export default function UnauthenticatedRoutes() {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          {unauthenticatedRoutes.map((x, i) => (
            <Route
              key={i}
              path={x.path}
              component={x.component}
              exact={x.exact}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </>
  );
}
