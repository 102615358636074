import React from "react";
import { MenuItem } from "@material-ui/core";
import Input from "./Input";

export default function Select({
  label,
  error,
  disabled,
  value,
  onChange,
  items,
  idKey,
  valueKey,
  all,
}) {
  return (
    <Input
      label={label}
      select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      disabled={disabled}
    >
      {all && (
        <MenuItem key="0" value="0">
          Todos
        </MenuItem>
      )}
      {items && items.map((item) => (
        <MenuItem key={item[idKey]} value={item[idKey]}>
          {item[valueKey]}
        </MenuItem>
      ))}
    </Input>
  );
}
