import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

export function useForm() {
  const [fields, setFields] = useState({});
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [validationRules, setValidationRules] = useState([]);
  const [errors, setErrors] = useState({});
  const [isValidationEnabled, setIsValidationEnabled] = useState(false);

  const validateFields = useCallback(
    () =>
      _.reduce(
        validationRules,
        (acumulator, rule) => {
          const obj = rule(fields);
          acumulator[obj.field] = obj.error;
          return acumulator;
        },
        {}
      ),
    [fields, validationRules]
  );

  const isFormValid = useCallback(
    () =>
      _.chain(validateFields())
        .values()
        .every((x) => !x)
        .value(),
    [validateFields]
  );

  useEffect(() => {
    if (isValidationEnabled) {
      const err = validateFields();
      setErrors(err);
    }
  }, [validateFields, isValidationEnabled]);

  const handleSubmit = useCallback(
    (action) => (e) => {
      e.preventDefault();

      setIsValidationEnabled(true);

      if (isFormValid()) {
        setIsSendingForm(true);
        Promise.resolve(action(fields)).then(() => {
          setIsSendingForm(false);
        });
      }
    },
    [fields, isFormValid]
  );

  const setFieldValue = useCallback(
    (field) => (value) => {
      const obj = { ...fields };
      obj[field] = value;
      setFields(obj);
    },
    [fields]
  );

  const getFieldValue = useCallback((field) => fields[field] ?? "", [fields]);

  const handleInputChange = useCallback(
    (field) => (event) => setFieldValue(field)(event.target.value),
    [setFieldValue]
  );

  const handleCheckboxChange = useCallback(
    (field) => (event) => setFieldValue(field)(event.target.checked),
    [setFieldValue]
  );

  const initializeFields = useCallback((x) => {
    setIsValidationEnabled(false);
    setErrors({});
    setFields({ ...x });
  }, []);

  const resetFields = useCallback(
    () => initializeFields({}),
    [initializeFields]
  );

  const configureValidations = useCallback(
    (validations) => setValidationRules([...validations]),
    []
  );

  const validate = useCallback(
    (fieldName, rules) => (fields) => ({
      field: fieldName,
      error: _.chain([rules])
        .flatten()
        .some((rule) => !rule(fields[fieldName]))
        .value(),
    }),
    []
  );

  return {
    handleSubmit,
    handleInputChange,
    handleCheckboxChange,
    getFieldValue,
    setFieldValue,
    isSendingForm,
    resetFields,
    initializeFields,
    configureValidations,
    validate,
    errors,
  };
}

export const required = (val) => val !== null && val !== undefined;

export const stringNotEmpty = (val) => required(val) && val.length > 0;

export const stringNotEmptyOrWhiteSpace = (val) =>
  required(val) && val.trim().length > 0;

export const emailFormat = (val) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(val);
};

export const logRule = (rule) => (val) => {
  console.log("🚀 ~ file: useForm.js ~ line 81 ~ logRule ~ val", val);
  const result = rule(val);
  console.log("🚀 ~ file: useForm.js ~ line 83 ~ logRule ~ result", result);
  return result;
};
